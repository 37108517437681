import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import BlockContent from '../block-content'
import RoleList from '../role-list/role-list'
import BlogPostTags from '../blog-tags/blog-post-tags'
import VideoBanner from '../video-banner/video-banner'

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

function BlogPost (props) {
  const { _rawBody, authors, _rawCategories, title, mainImage, publishedAt } = props
  return (
    <>
			<VideoBanner { ...props}/>
			<Container 
				fluid
				className={'post-container'}
				style={{paddingBottom: 40, paddingTop: 40}}>
				<Row>
					<Col md={8} className={'mr-auto ml-auto'}>
						<Row>
							<Col md={12} style={{paddingTop: 30}}>
								{_rawBody && <BlockContent blocks={_rawBody} />}
							</Col>
						</Row>
						<Row>
							<Col md={12} style={{paddingTop: 30}}>
								{_rawCategories && (
									<BlogPostTags categories={_rawCategories} label/>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
    </Container>
    </>
  )
}

export default BlogPost
